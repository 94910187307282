
import './lmkExpireBadge.scss';
import { defineComponent, computed, PropType } from 'vue';
import { LmkStatus } from '@/lib/Lmk';

export default defineComponent({
  name: 'LmkExpireBadge',
  props: {
    status: {
      type: String as PropType<LmkStatus>,
      required: false,
    },
  },
  setup(props) {
    const styleClasses = computed(() => {
      const wrapperClass = 'lmk-expire-badge';
      const statusModifier = props.status?.replace(/_?lmk_?/, '') ?? 'expired';
      const modifierClass = `${wrapperClass}--${statusModifier}`;

      return [wrapperClass, modifierClass];
    });

    const tooltip = computed(() => {
      switch (props.status) {
        case LmkStatus.Expired:
          return 'Срок действия истек';
        case LmkStatus.ExpiredSoon:
          return 'Срок действия заканчивается';
        case LmkStatus.Valid:
          return 'ЛМК действительна';
      }

      return 'ЛМК отсутствует';
    });

    return { styleClasses, tooltip };
  },
});
