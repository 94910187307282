
import { Component, Vue } from '@/lib/decorator';
import { ref } from 'vue';
import TitleReturn from '@/components/TitleReturn.vue';
import IconFont from '@/components/icons/IconFont.vue';
import MapMarkerIcon from '@/components/icons/MapMarker.vue';
import StarIcon from '@/components/icons/Star.vue';
import SmileIcon from '@/components/icons/Smile.vue';
import PencilPlusIcon from '@/components/icons/PencilPlus.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormSelect from '@/components/form/Select/index.vue';

import EmployeesFreeShifts from '@/store/tasks/freeMarketShifts/employees';
import UserModule from '@/store/user';
import formValidate from '@/lib/formFactory/validate';

import { EMPTY_BODY_BY_CRITERIA, ShiftEmployeeStatus } from '@/lib/util/consts';
import { EventBus } from '@/lib/event-bus';
import { debounce } from '@/lib/util/helpers';
import { makePhoneCall } from '@/lib/util/phone';
import { minutesToTime } from '@/lib/Utils';
import { PushStatus } from '@/lib/EmployeesFreeShifts';
import { getEmployeePhone } from '@/api/employees';
import AppTable from '@/components/ui/table/Table.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterEntityModel, FilterSettings } from '@/lib/layouts/page/filter.interface';
import { ShiftEntityInterface } from '@/store/shops/shiftModalParams';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import Search from '@/components/Search.vue';
import TimeLimit from '@/components/table-items/TimeLimit.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import FavoriteItem from '@/components/table-items/FavoriteItem.vue';
import LmkExpireBadge from '@/components/table-items/LmkExpireBadge.vue';
import WorkHoursBadge from '@/components/table-items/WorkHoursBadge.vue';
import ModalBlock from '@/components/Modal.vue';
import ModalBtnBottomLayout from '@/layouts/ModalBtnBottom.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import { CommentModalParamsInterface } from '@/store/tasks/freeMarketShifts/commentModalParams';
import TextOverflow from '@/components/table-items/TextOverflow.vue';
import { GuiLoader, GuiButton } from '@library/gigant_ui';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import ShiftModal from '@/views/shops/shift/form/shiftModal.vue';
import TimeFormatter from '@/components/TimeFormatter.vue';

@Component({
  components: {
    TimeFormatter,
    TextOverflow,
    FormTextarea,
    FormButton,
    ModalBtnBottomLayout,
    ModalBlock,
    FavoriteItem,
    TextDatetime,
    TimeLimit,
    Search,
    AppCol,
    AppRow,
    TextPhone,
    AppTable,
    TitleReturn,
    IconFont,
    FormInput,
    FormSelect,
    GuiLoader,
    MapMarkerIcon,
    StarIcon,
    SmileIcon,
    LmkExpireBadge,
    WorkHoursBadge,
    PencilPlusIcon,
    GuiButton,
    ShiftModal,
  },
})
export default class FreeMarketShiftsEdit extends Vue {
  DEBOUNCE_TIME = 600;
  debouncedUpdateSearchName = debounce(this.updateSearchName.bind(this), this.DEBOUNCE_TIME);
  debouncedUpdateDistance = debounce(this.updateSearch.bind(this), this.DEBOUNCE_TIME);

  employeePhones: Record<string, string> = {};
  maxDistance = '';
  comment = '';
  hasError = false;
  showInfoModal = false;
  statuses: Record<number | string, Record<string, Record<string, string | boolean>>> = {};

  form = ref(null);
  formValidate = formValidate(this.form);

  isShowShiftModal = false;

  tooltipText = {
    'person-check': 'Запланировать',
    'person-off': 'Отказался',
  };

  pushButtonTooltip: Record<PushStatus, string> = {
    [PushStatus.IsNotSent]: 'Отправить пуш',
    [PushStatus.IsSent]: 'Пуш отправлен',
    [PushStatus.IsFailed]: 'Ошибка отправки',
    [PushStatus.IsDelivered]: 'Пуш доставлен',
    [PushStatus.IsRead]: 'Пуш прочитан',
  };

  pushButtonIcon: Record<PushStatus, string> = {
    [PushStatus.IsNotSent]: 'push-message',
    [PushStatus.IsSent]: 'clock',
    [PushStatus.IsFailed]: 'push-message',
    [PushStatus.IsDelivered]: 'check-mark',
    [PushStatus.IsRead]: 'check-double',
  };

  get settings(): PageInterface {
    return EmployeesFreeShifts.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return EmployeesFreeShifts.filter.filterSettings;
  }

  get isLoading(): boolean {
    return EmployeesFreeShifts.isLoading;
  }

  get fullName(): FilterEntityModel {
    return EmployeesFreeShifts.filter.filterSettings.filterModel.fullName;
  }

  get timeLimit(): FilterEntityModel {
    return EmployeesFreeShifts.filter.filterSettings.filterModel.timeLimit;
  }

  get shift(): ShiftEntityInterface {
    return EmployeesFreeShifts.shift as ShiftEntityInterface;
  }

  get commentModalParams(): CommentModalParamsInterface {
    return EmployeesFreeShifts.commentModalParams as CommentModalParamsInterface;
  }

  get emptyBodyText(): string {
    return EMPTY_BODY_BY_CRITERIA;
  }

  get isSupervisor(): boolean {
    return UserModule.isSupervisor;
  }

  get lunchMinutes(): string {
    return minutesToTime(this.shift.lunch ?? 0);
  }

  prepareRating(value: string): string {
    return value.substring(0, 3);
  }

  sort(header: TableHeaderInterface): void {
    EmployeesFreeShifts.sort({ field: header.id, sort: header.sort?.value || '' });
  }

  returnBefore(): void {
    this.$router.push({ name: 'free_market_shifts' });
  }

  openCommentModal(employeeId: number, comment: string): void {
    this.comment = comment;
    this.hasError = false;
    EmployeesFreeShifts.context.commit('TOGGLE_COMMENT_MODAL', { isShow: true, employeeId: employeeId });
  }

  closeCommentModal(): void {
    EmployeesFreeShifts.context.commit('TOGGLE_COMMENT_MODAL', false);
  }

  toggleShowInfoModal(): void {
    this.showInfoModal = !this.showInfoModal;
  }

  selectAmount(value: string): void {
    EmployeesFreeShifts.updatePageAmountItems(value);
  }

  iconWhoseType(value: string): { icon: string; tooltip: string } {
    switch (value) {
      case 'fromPartner': {
        return { icon: 'partners', tooltip: 'От партнёра' };
      }
      case 'fromHome': {
        return { icon: 'home', tooltip: 'Свой' };
      }
      case 'fromAgent': {
        return { icon: 'scout', tooltip: 'От скаута' };
      }
    }

    return { icon: '', tooltip: '' };
  }

  async save(): Promise<void> {
    if (this.comment.length < 3) {
      this.hasError = true;

      return;
    }
    await EmployeesFreeShifts.saveComment(this.comment);
  }

  getSelected(item: { statusId: string; substatusId: string }, key: string): boolean {
    const generalStatus = item.substatusId ?? item.statusId ?? '';
    switch (generalStatus) {
      case 'is_planned':
      case 'came_out':
      case 'fake_call':
      case 'fast_replacement':
        return key == 'planned';
      case 'refused_at_this_time':
      case 'refused_in_this_market':
        return key == 'declinedTime';
      default:
        return false;
    }
  }

  getActions(id: string): Record<string, Record<string, string | boolean>> {
    if (!this.statuses[id]) {
      this.statuses[id] = {
        planned: {
          icon: 'person-check',
          value: false,
        },
        declinedTime: {
          icon: 'person-off',
          value: false,
        },
      };
    }

    return this.statuses[id];
  }

  getPushClassesByStatus(status: string): string {
    switch (status) {
      case PushStatus.IsNotSent:
        return 'app-table-icon--active';
      case PushStatus.IsFailed:
        return 'app-table-icon--error';
      case PushStatus.IsRead:
        return 'app-table-icon--success';
    }

    return '';
  }

  formatDistance(distance: string | number): number {
    return Number(distance) || 0;
  }

  trackByItemId(item: ShiftEntityInterface): string {
    return item.id;
  }

  async updateFilter(): Promise<void> {
    EmployeesFreeShifts.CHANGE_CURRENT_PAGE(1);
    await EmployeesFreeShifts.filter.updateFilter();
  }

  async handleSelect(selected: {}, params: { key: string; action: string }): Promise<void> {
    await this.$store.dispatch(params.action, {
      key: params.key,
      value: selected,
    });

    await this.updateFilter();
    await EmployeesFreeShifts.getList();
  }

  async updateSearchName(value: string): Promise<void> {
    EmployeesFreeShifts.filter.updateSearch({ key: 'fullName', value });
    await this.updateFilter();
    await EmployeesFreeShifts.getList();
  }

  async updateSearch(value: string): Promise<void> {
    const normalizedValue = value.replace(',', '.');
    const parsedValue = parseFloat(normalizedValue).toString();

    EmployeesFreeShifts.filter.updateSearch({ key: 'maxDistance', value: parsedValue });
    await this.updateFilter();
    await EmployeesFreeShifts.getList();
  }

  changeTableEmployeeStatus(employeeId: string, newStatus: string): void {
    const employees = (this.settings.table as { rows: { id: string; statusId: string | null }[] }).rows;
    const changedEmployee = employees.find((employee) => employee.id === employeeId);

    if (changedEmployee) {
      changedEmployee.statusId = newStatus;
    }
  }

  async selectStatus(params: { id: string; status: string; value: boolean }): Promise<void> {
    if (['planned', ShiftEmployeeStatus.Reserve].includes(params.status)) {
      const { countEmployee: beforeRequestCountEmployee, needCount } = EmployeesFreeShifts.shift as ShiftEntityInterface;
      let isSuccessEmployeeUpdated = false;

      if (beforeRequestCountEmployee < needCount) {
        isSuccessEmployeeUpdated = await this.updateEmployeeStatus(params);
      }

      if (!isSuccessEmployeeUpdated) {
        params.status = ShiftEmployeeStatus.Reserve;
        isSuccessEmployeeUpdated = await this.updateEmployeeStatus(params);
      }

      const { countEmployee } = EmployeesFreeShifts.shift as ShiftEntityInterface;
      const isFullOrOverflowShift = countEmployee >= needCount;

      if (isFullOrOverflowShift && isSuccessEmployeeUpdated) {
        this.changeTableEmployeeStatus(params.id, ShiftEmployeeStatus.IsPlanned);
        ResponseHandlerModule.showNotify({ message: 'Смена полностью укомплектована', type: 'ok' });
      } else if (!isFullOrOverflowShift) {
        await EmployeesFreeShifts.getList();
      }
    } else if (params.status === 'declinedTime') {
      await EmployeesFreeShifts.shiftRefuse(params.id);
      await EmployeesFreeShifts.getList();
    }
  }

  async updateEmployeeStatus(params: { id: string; status: string; value: boolean }): Promise<boolean> {
    const result = await EmployeesFreeShifts.updateStatusById(params);

    if (result) {
      await EmployeesFreeShifts.getShiftByID(this.shift.id.toString());
    }

    return result;
  }

  async sendPush(item: { id: string; pushStatus: PushStatus }): Promise<void> {
    if (item.pushStatus !== PushStatus.IsNotSent) {
      return;
    }

    return EmployeesFreeShifts.sendPushMessage(item.id);
  }

  async savedEditShift(): Promise<void> {
    await EmployeesFreeShifts.initList();
  }

  async showPhoneOrMakeCall(employeeId: string, employeePhone: string): Promise<void> {
    const phoneNumber = this.employeePhones[employeeId] || employeePhone;
    const isHiddenPhone = phoneNumber.includes('*');

    if (isHiddenPhone) {
      this.employeePhones[employeeId] = await getEmployeePhone(employeeId);

      return;
    }

    makePhoneCall(phoneNumber);
  }

  async mounted(): Promise<void> {
    try {
      await EmployeesFreeShifts.init(this.$route.params.shiftId as string);
    } catch {
      this.$router.push({ name: 'free_market_shifts' });
    }

    EventBus.$on('savedShift', this.savedEditShift);
  }

  unmounted(): void {
    EventBus.$off('savedShift', this.savedEditShift);
  }
}
