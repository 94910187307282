import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "m7.4 8.28.44.44c.24.25.64.25.88 0a.63.63 0 0 0 0-.88L8 7.11a.83.83 0 0 0-1.17 0l-.75.73a.63.63 0 0 0 0 .88c.25.25.65.25.89 0l.44-.44Zm2.6 6.3c1.7 0 3.17-.92 3.96-2.29a.42.42 0 0 0-.37-.62H6.41a.42.42 0 0 0-.37.62A4.57 4.57 0 0 0 10 14.6Zm1.28-5.86c.24.25.64.25.88 0l.44-.44.44.44c.24.25.64.25.89 0a.63.63 0 0 0 0-.88l-.74-.73a.83.83 0 0 0-1.17 0l-.74.73a.62.62 0 0 0 0 .88ZM9.98 1.67a8.32 8.32 0 1 0 0 16.64 8.32 8.32 0 0 0 0-16.64Zm.01 15a6.66 6.66 0 1 1 0-13.33 6.66 6.66 0 0 1 0 13.33Z"
    }, null, 8, _hoisted_2)
  ]))
}