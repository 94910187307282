import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M10 3.333c1.833 0 3.333 1.5 3.333 3.333 0 1.75-1.75 4.583-3.333 6.583-1.583-2.083-3.333-4.833-3.333-6.583 0-1.833 1.5-3.333 3.333-3.333Zm0-1.667c-2.75 0-5 2.25-5 5 0 3.75 5 9.167 5 9.167s5-5.5 5-9.167c0-2.75-2.25-5-5-5Zm0 3.333c-.917 0-1.667.75-1.667 1.667S9.083 8.333 10 8.333s1.667-.75 1.667-1.667-.75-1.667-1.667-1.667Zm6.667 10.834c0 1.833-3 3.333-6.667 3.333s-6.667-1.5-6.667-3.333c0-1.084 1-2 2.584-2.667l.5.75c-.834.417-1.417.917-1.417 1.5 0 1.167 2.25 2.083 5 2.083s5-.916 5-2.083c0-.583-.583-1.083-1.5-1.5l.5-.75c1.667.667 2.667 1.583 2.667 2.667Z"
    }, null, 8, _hoisted_2)
  ]))
}