
import './workHoursBadge.scss';
import { defineComponent, computed } from 'vue';
import { getPlural } from '@/lib/Utils';

export default defineComponent({
  props: {
    employeeHours: {
      type: Number,
      required: false,
      default: 0,
    },
    calendarHours: {
      type: Number,
      required: false,
      default: 0,
    },
    hasRotation: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const wrapperClass = 'work-hours-badge';
    const employeeHours = computed(() => props.employeeHours || 0);
    const calendarHours = computed(() => props.calendarHours || 0);

    const classNames = computed(() => {
      let modifier;

      if (calendarHours.value && props.hasRotation) {
        if (employeeHours.value > calendarHours.value * 0.8) {
          modifier = 'critical';
        } else if (employeeHours.value > calendarHours.value * 0.5) {
          modifier = 'warning';
        } else {
          modifier = 'normal';
        }
      }

      const result = [wrapperClass];

      if (modifier) {
        result.push(`${wrapperClass}_${modifier}`);
      }

      return result;
    });

    const tooltip = computed(() => {
      const pluralHours = getPlural(calendarHours.value, ['час', 'часа', 'часов']);
      const tooltipMessage = `Предельная выработка в этом месяце - ${calendarHours.value} ${pluralHours}`;

      return calendarHours.value && props.hasRotation ? tooltipMessage : '';
    });

    return { classNames, tooltip, value: employeeHours };
  },
});
