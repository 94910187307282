import moment from 'moment';

export enum LmkStatus {
  Valid = 'valid_lmk',
  Expired = 'lmk_expired',
  ExpiredSoon = 'lmk_expired_soon',
}

export function getExpireStatus(lmkExpire: Date, shiftStart: Date): LmkStatus | null {
  const isInvalidDates = [lmkExpire.valueOf(), shiftStart.valueOf()].some(isNaN);

  if (isInvalidDates) {
    return null;
  }

  const lmkExpireMoment = moment(lmkExpire);
  const shiftStartMoment = moment(shiftStart);
  const diffDays = lmkExpireMoment.diff(shiftStartMoment, 'days');
  let status = LmkStatus.ExpiredSoon;

  if (diffDays <= 0) {
    status = LmkStatus.Expired;
  } else if (diffDays > 30) {
    status = LmkStatus.Valid;
  }

  return status;
}
