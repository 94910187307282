import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "m9.4 6 .6.6-6.07 6.07h-.6v-.6L9.4 6Zm2.4-4a.72.72 0 0 0-.47.2l-1.2 1.2 2.47 2.53 1.2-1.26a.64.64 0 0 0 0-.94L12.27 2.2a.72.72 0 0 0-.47-.2ZM9.4 4.13 2 11.47V14h2.53l7.34-7.4L9.4 4.13Zm-4.73-2.8v2h2v1.34h-2v2H3.33v-2h-2V3.33h2v-2h1.34Z"
    }, null, 8, _hoisted_2)
  ]))
}