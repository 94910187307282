<template>
  <span class="time-limit" :class="className" v-tooltip="title" />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TimeLimit',
  props: {
    state: {
      type: Number,
    },
  },
  computed: {
    className() {
      switch (this.state) {
        case 1:
          return 'time-limit--full-time';
        case 2:
          return 'time-limit--no-time';
        case 3:
          return 'time-limit--half-time';
        case 4:
          return 'time-limit--missed-time';
      }

      return '';
    },
    title() {
      switch (this.state) {
        case 1:
          return 'Подходящий';
        case 2:
          return 'Нет графика';
        case 3:
          return 'Неполное';
        case 4:
          return 'График не совпадает';
      }

      return '';
    },
  },
});
</script>

<style lang="scss" scoped>
.time-limit {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 95%;
  border-radius: 0 10px 10px 0;

  &--full-time {
    background-color: #27ae45;
  }

  &--missed-time {
    background-color: #ff5964;
  }

  &--half-time {
    background-color: #ffc42b;
  }

  &--no-time {
    background-color: #dddde3;
  }
}
</style>
